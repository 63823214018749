.header__desktop_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5.625rem;
    width: 100%;
    .right_wrapper {
        display: flex;
        align-items: center;
        position: relative;
        .right_wrapper__default {
            display: flex;
            align-items: center;
            padding: 0.5rem;
            cursor: pointer;
        }
        .logout_wrapper {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            padding: 1rem 0.5rem;
            background-color: white;
            overflow: hidden;
            transition: all 250ms ease-in-out;
            z-index: 1000;
            display: flex;
            flex-direction: column;
            .logout_btn,.edit_acc_btn,.live_sess_btn {
                display: flex;
                align-items: center;
                justify-content: space-around;
                height: 40px;
                width: 90%;
                margin: 0 auto;
                background-color: $soft-teal;
                padding: 0.5rem;
                border-radius: 8px;
                color: white;
            }
        }
        .logout {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;

            button {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                font-size: 0.875rem;
                transition: all 250ms ease-in-out;
            }
            button:hover {
                color: $soft-teal;
            }
        }
    }
    &__links_container {
        height: 100%;
        display: flex;
        justify-content: center;
        flex: 1;
        .links_wrapper {
            width: 23.75rem;
            display: flex;
            li {
                display: flex;
                justify-content: center;
                align-items: center;
                list-style-type: none;
                width: 100%;
                text-align: center;
                cursor: pointer;
                position: relative;
            }
            li:hover {
                color: $soft-teal;
            }
            .link-active::after {
                content: '';
                height: 4px;
                width: 100%;
                background-color: $soft-teal;
                position: absolute;
                left: 0;
                bottom: 0;

            }
        }
    }
}

@media screen and (max-width: 720px) {
    .header__desktop_nav {
        display: none;
    }
}

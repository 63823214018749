.lesson__sidebar__planner__weeks {
  padding: 0 30px;
  display: grid;
  grid-template-columns: 1.625rem 1fr;
  grid-column-gap: 1.3125rem;

  &__grid_col_1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1.875rem 1fr;
    justify-items: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    padding-top: 2px;

    .line {
      width: 1px;
      height: 90%;
      background-color: $white;
      opacity: 0.2;
    }
  }

  &__grid_col_2 {
    flex: 1;
    padding-bottom: 1rem;

    header {
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .duration {
        background-color: rgba($color: #fff, $alpha: 0.14);
        padding: 0.5rem 1.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6.5rem;
        height: auto;
      }
    }

    .accordion {

      h4 {
        margin-top: 0.5rem;
      }
      h4, .module_title {

        button {
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          grid-column-gap: 0.375rem;
          margin-bottom: 0.875rem;
          text-align: left;
          line-height: 1.2rem;
        }
 
        cursor: pointer;
        transition: 0.2s ease-in;

        &.active {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 100px;
          padding: 10px;
          transform: translateX(-10px);
        }
        &.webinar{
          color: $soft-teal;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      .accordion_header {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.8125rem;
        width: 100%;
      }

      ul {
        height: 0;
        overflow: hidden;
        list-style-type: none;
        padding: 0 !important;
        li {
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          grid-column-gap: 0.375rem;
          margin-bottom: 0.875rem;
        }
      }

      .accordion-active {
        height: 100%;
        overflow: visible;
      }
    }
  }
}

.lesson__sidebar__planner__weeks:nth-last-child(1) {
  .line {
    display: none;
  }
}


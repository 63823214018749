// .login_page {
//     height: 100vh;
//     display: grid;
//     grid-template-columns: 50% 50%;
// }


.login_page {
    height: 100vh;
    width: 100%;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: fixed;
        z-index: -1;
    }
    .overlay {
        position: absolute;
        mix-blend-mode: multiply;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $admiral-blue;
        opacity: 0.5;
        z-index: 0;
    }
    &__container_left {
        height: 100%;
        position: absolute;
        z-index: 5;
        .terms_and_conditions {
            height: 100%;
            color: white;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            padding: 2rem;
        }
    }
    &__container_right {
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        color: #fff;
        text-align: center;
        max-width: 30rem;
        form {
            display: flex;
            flex-direction: column;
            text-align: left;
            gap: 0.5rem;
            margin-top: 2rem;
            position: relative;
            label {
                font-size: 14px;
                opacity: 0.7;
                text-transform: uppercase;
            }
            input {
                height: 50px;
                border-radius: 6px;
                border: none;
                outline: none;
                padding: 0 1rem;
            }
            button {
                width: 100%;
                height: 4rem;
                border-radius: 100px;
                border: none;
                background: linear-gradient(90deg, #262261 0%, #6BCAD2 100%);
                color: $white;
            }
        }
    }
}   


@media screen and (min-width: 1080px) {
    .login_page {
        display: grid;
        grid-template-columns: 50% 50%;
        img {
            position: fixed;
            width: 50%;
            border-radius: 0 0 5rem 0;
        }
        .overlay {
            width: 50%;
            border-radius: 0 0 5rem 0;
        }
        &__container_left, &__container_right {
            position: relative;
        }
        
        &__container_right {
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            color: $admiral-blue;
            form {
                margin: 2rem 1rem 0;
            }
        }
        &__container_left {
            .terms_and_conditions {
                padding: 4rem;
            }
        }
    }
}
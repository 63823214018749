.dashboard__mobile_sidebar {
    background: linear-gradient(0deg, #6BCAD2 -150%, #21273D 80% );
    color: $white;
    position: relative;
    &__carousel {
        .carousel_container {
            overflow-x: hidden;
            display: flex;
            position: relative;
            height: 28.5rem;
            .user_information, .user_awards {
                min-width: 100%;
                position: absolute;
                top: 0;
                transition: all 500ms ease-in-out;
            }
            .user_information {
                left: 0;
                .dashboard__account_level {
                    hr {
                        display: none;
                    }
                }
            }
            .user_awards {
                left: 100%;
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
                .dashboard__badges {
                    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
                    hr {
                        display: none;
                    }
                }
            }
        }
        .slide_left, .slide_right {
            border: none;
            background-color: transparent;
            color: $white;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            z-index: 5;
        }
        .slide_right {
            right: 1rem;
        }
        .slide_left {
            left: 1rem;
        }
    }
}



@media screen and (min-width: 1400px) {
    .dashboard {
        &__mobile_sidebar {
            display: none;
        }
    }
}
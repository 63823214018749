@import './components/Sidebar/Sidebar';
@import './components/MyCourses/MyCourses';
@import './components/MobileSidebar/MobileSidebar';

.dashboard {
    height: 100%;
    &__container {
        display: flex;
        &__main_loading {
            width: 100%;
            text-align: center;
            margin-top: 10px;
        }
        &_main{
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            overflow-y: auto;
            &__fallback_hero {
                min-height: 30rem;
                width: 100%;
            }
            &__banner{
                background-color: $blue-gray;
                background: linear-gradient(0deg, $green-1 20%, $green-2 100%);
                min-height: 30rem;
                color: $white;
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;
                font-size: 2rem;
                padding-left: 5rem;
                background-size: cover;
                background-blend-mode: overlay;
                background-color: rgba(0, 0, 0, 0.25);
                position: relative;
                &-gradient {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(to top right, rgba(32, 39, 61, 0.9), rgba(0, 0, 0, 0));
                    pointer-events: none;
                }
                &-content {
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    margin-top: 9%;
                }
                .name {
                    padding-left: 1rem !important;
                    font-size: 3.6rem;
                }
                .stats {
                    padding-left: 1rem;
                    padding-top: 1.5rem;
                    font-size: 0.875rem;
                }
                .description {
                    padding-left: 1rem;
                    font-size: 1.25rem;
                    max-width: 30rem;
                    padding-top: 1.5rem;
                }
                .next-module-wrapper {
                    font-size: 1.25rem;
                    margin-left: 1rem;
                    margin-top: 1rem;
                    display: flex;
                    align-items: center;
                }
                .continue_learning_btn {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    height: 40px;
                    margin-top: 1rem;
                    margin-left: 1rem;
                    background-color: $soft-teal;
                    border: 0;
                    padding: 0.5rem 1rem;
                    border-radius: 8px;
                    color: $dark-blue;
                    font-size: 1rem;
                }
                .percentage, .lesson_count {
                    color: $soft-teal;
                }
                .divider {
                    margin-right: 4px;
                    border: 0.5px solid $soft-teal;
                }
                .play_icon {
                    margin-right: 0.5rem;
                    color: $soft-teal;
                }
                &_image{
                    display: none;
                    position: relative;
                    flex-basis: 250px;
                    height: 100%;
                    overflow: hidden;

                    img{
                        left: 35%;
                        bottom: -2px;
                        height: 90%;
                        position:absolute;
                        transform: scaleX(-1);
                    }
                    span{
                        position: absolute;
                        display: block;
                        background: rgba(255,255,255,0.75);
                        border: 3px $white solid;
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        opacity: 0.15;
                        &.blob-1{
                            top: 60%;
                            left: -10px; 
                        }
                        &.blob-2{
                            top: 30%;
                            left: 30%;
                            opacity: 0.1;
                        }
                        &.blob-3{
                            top: -20px;
                            left: 50%; 
                            opacity: 0.1; 
                        }
                        &.blob-4{
                            top: 30px;
                            left: 20%; 
                        }
                        &.blob-5{
                            top: 90%;
                            left: 70%; 
                            opacity: 0.05;
                        }
                        &.blob-6{
                            top: 50%;
                            left: 10px;
                            opacity: 0.05; 
                        }
                        &.blob-7{
                            top: 50%;
                            left: 80%; 
                            opacity: 0.05;
                        }
                        &.blob-8{
                            top: 20px;
                            left: 30%; 
                            opacity: 0.05;
                        }
                        &.blob-9{
                            top: 70%;
                            left: 70%; 
                        }
                        &.blob-10{
                            top: 10%;
                            left: 60%; 
                        }
                    }
                    &:before{
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: radial-gradient(circle, rgba($soft-teal,0) 50%, $soft-teal 100%);
                        background-size: 300%;
                        left:0;
                    }
                }
                &_content{
                    flex: 1 1 auto;
                    text-transform: uppercase;
                    letter-spacing: 8px;
                    padding: 2rem;
                    h1{
                        color: $soft-teal;
                        font-weight: 400;
                        padding: 0!important;
                    }
                    h2{
                        font-size: 1.5rem;
                    }
                
                }
            }
            &__my_courses {
                width: 100%;
                padding: 2rem;
            }
        }
        
    }
    &__sidebar_container {
        max-width: 25rem;
    }
    @media screen and (min-width: 1080px) {
        
        display: flex;
        flex-direction: column;
        height: 100vh;
        &__container {
            height: calc(100vh - 5.625rem);
            overflow-y: hidden;
            &_main {
                
                &__banner{
                    &_image{
                        display: block;
                    }
                    &_content{
                        padding: 5rem;
                        h2{
                            font-size: 2.5rem;
                        }
                    
                    }
                }
                &__my_courses {
                    padding: 5rem;
                    padding-top: 1rem;
                }
            }
            
        }
        
    }
}


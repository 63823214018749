@import './VideoChat/VideoChat';

.lesson__video_player {
  width: 100%;
}

.video-player {
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  width: 100%;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: 0;
  }
}
/*
@media (min-width: 600px) {
  .video-player {
    iframe {
      top: calc(-50vw * 0.094);
    }
  }
}

@media (min-width: 1178px) {
  .video-player {
    margin-bottom: -200px;

    iframe {
      top: calc(-50vw * 0.1);
    }
  }
}

@media (min-width: 1417px) {
  .video-player {
    iframe {
      top: calc(-50vw * 0.11);
    }
  }
}

@media (min-width: 1809px) {
  .video-player {
    iframe {
      top: calc(-50vw * 0.115);
    }
  }
}

@media (min-width: 2153px) {
  .video-player {
    iframe {
      top: calc(-50vw * 0.118);
    }
  }
}
*/



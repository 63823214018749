@import './VideoPlayer/VideoPlayer';

.module_header{
    &.webinar{
        @media (min-width: 800px) {
            display: flex;
            flex-wrap: wrap;
        }
        & > *{
            flex-grow: 1;
            max-width: 100%;
            @media (min-width: 1390px) {
                &.video-chat{
                    flex-grow: 0;
                }
            }
        }
        
    }
}
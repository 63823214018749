@import './Header/Header';
@import './Planner/Planner';
.lesson__sidebar {
    background: linear-gradient(0deg, #6BCAD2 -150%, #21273D 80% );
    width: 100%;
    color: $white;
    transition: all 300ms ease-in-out;
}


@media screen and (min-width: 800px) {
    .lesson__sidebar {
        width: 25rem;
        
    }
}


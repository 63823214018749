.live-sessions {
  .live-sessions_container {
    padding: 0 5rem;
    @media screen and (max-width: 450px) {
      padding: 0 1rem;
    }
  }
  h1 {
    margin: 3.875rem 0 2.25rem 0;
    padding-left: 0!important;
    padding-bottom: 1rem;
  }

  .button {
    border-radius: 8px;
    opacity: 1;
    text-transform: capitalize;
    background-color: transparent;
    color: $admiral-blue;
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    font-weight: 500;
    border: 1px solid #21273c10;
    &.active {
      background-color: #e6e8e9;
    }
}
  .table-view {
    width: 100%;
    border: 1px solid #E7E7E9;
    border-radius: 10px;
    border-spacing: 0;
    background-color: white;
    thead {
      tr {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        th {
          padding: 1rem;
          text-transform: uppercase;
          text-align: left;
          font-weight: 700;
          font-size: 14px;
          background-color: #e6e8e9;
          &:first-of-type {
            border-top-left-radius: 9px;
          }
          &:last-of-type {
            border-top-right-radius: 9px;
          }
        }
      }
    }
    td {
      padding: 1rem;
      border-bottom: 1px solid #e6e8e9;
    }
    tr {
      cursor: pointer;
      &:hover {
        background-color: #f1f1f3;
      }
      &:last-of-type {
        &:hover {
          background-color: white;
          cursor: initial;
        }
        td {
          border-bottom: 0;
        }
      }
    }
  }
  .pagination {
    min-height: 1rem;
    position: relative;
    display: flex;
    gap: 1rem;
    justify-content: center;
    .arrows {
      color: #90939e;
      position: absolute;
      right: 1rem;
      display: flex;
      gap: 0.5rem;
      .arrow {
        &:hover {
          color: initial;
          cursor: pointer;
        }
        &.active {
          color: initial;
        }
      }
    }
    .page {
      cursor: pointer;
      color: #90939e;
      &:hover {
        color: initial;
      }
      &.active {
        color: initial;
      }
    }
  }
  .card-list {
    .card {
      padding: 1rem;
      background-color: white;
      border-radius: 10px;
      margin-top: 1rem;
      margin-bottom: 1rem;
      cursor: pointer;
      &:hover {
        background-color: #e6e8e9;
      }
      .academy-name {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .acedemy-icon {
          margin-top: -3px;
          color: $soft-teal;
        }
      }
      .session-name {
        margin-top: 10px;
        font-weight: 500;
      }
      .time {
        margin-top: 10px;
      }
      .instructor {
        margin-top: 15px;
      }
      hr {
        width: calc(100% + 2rem);
        margin-left: -1rem;
        border: 0.5px solid #e6e8e9;
        margin-top: 10px;
      }
    }
  }

  .page-filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    .month-filter {
      display: flex;
      gap: 0.5rem;
    }
  }

  .month-filter {
    border-radius: 8px;
    opacity: 1;
    text-transform: capitalize;
    background-color: transparent;
    color: $admiral-blue;
    height: 50px;
    width: 120px;
    font-size: 1rem;
  }
  .view-switch {
    display: flex;
    gap: 0.5rem;
  }

  .calendar-view {
    .fc-daygrid-event-dot {
      display: none;
    }
    .fc-daygrid-event {
        background-color: #6bcad240;
        height: 100% !important;
    }
    .fc-scrollgrid-liquid {
        th {
            border: none;
        }
        border-radius: 8px;
        height: 865px !important;
    }

    .fc-col-header-cell {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        border: none;
        height: 2rem;
        padding: 1rem 0;
    }
    .fc {
        border: none;
    }
    .fc-daygrid-day-frame {
        .fc-daygrid-day-top {
            a {
                text-align: left !important;
                width: 100%;
            }
        }
    }
    .fc-view-harness {
        table {
            border: none;
            thead {
                border: none;
            }
            tbody {
                background-color: $white;
            }
        }
    }
    .fc-toolbar {
      display: none;
    }
    .fc-event-title  {
        font-weight: 500 !important;
        white-space: break-spaces;
        text-transform: capitalize;
        font-size: 14px;
    }
    .fc-day-today {
        background-color: rgba($soft-teal, 0.25)!important;
    }
  
    .fc-daygrid-event {
        padding: 1rem 1.5rem;
        display: flex !important;
        flex-direction: column-reverse;
        align-items: flex-start;
        position: relative;
        cursor: pointer;
    }
    .fc-daygrid-event:hover {
        background-color: #6bcad240;
    }
    .fc-daygrid-event::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 70%;
        background-color: $soft-teal;
        border-radius: 2px;
        top: 50%;
        left: 0.5rem;
        transform: translate(0%, -50%);
    }
  
    .fc .fc-daygrid-day-frame {
        padding: 0.5rem;
    }
  
    .fc-list-table {
      margin-top: 40px;
      max-width: 720px !important;
      .fc-list-day-cushion {
          background-color: white;
          margin: 1rem 0;
      }
    }
    .fc-toolbar-chunk {
        min-width: 100% !important;
    }
    .fc-toolbar-chunk:nth-child(1) {
        position: absolute;
        top: 150px;
        border: none !important;
    }
  }
}
@import './components/Weeks/Weeks';

.lesson__sidebar__planner {
  padding:  0;
  height: 0;
  overflow-y: auto;
  margin-bottom: 0;
  &.expanded {
    height: 100%;
    padding: 2rem 0;
  }
}

@media screen and (min-width: 800px) {
    .lesson__sidebar__planner {
        height: 100vw!important;
        padding: 2rem 0;
    }
}

@import './AccountLevel/AccountLevel';
@import './Badges/Badges';
@import './CertificatesUnlocked/CertificatesUnlocked';

.dashboard__sidebar_gamification{
    margin: 2rem;
    padding: 2.25rem 1.5rem;
    background: radial-gradient(86.11% 86.12% at 0% 100%, $royal-blue 0%, $soft-teal 100%);
    border-radius: 8px;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &_title{
        font-size: 1.6rem;
    }
}
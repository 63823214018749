.dashboard__account_level {
    position: relative;
    .text_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .progress_bar {
        height: 4px;
        width: 100%;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-top: 5px;
        background: white;
    }
    .progress_bar::before  {
        content: '';
        background-color: $white;
        opacity: 0.1;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .progress_bar::after {
        content: '';
        background-color: $soft-teal;
        opacity: 1;
        height: 100%;
        width: 160px;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 5px;
    }
}
@import './Header/SidebarHeader';
@import './Gamification/SidebarGamification';

.dashboard {
    &__sidebar {
        max-width: 25rem;
        width: 25rem;
        height: 100vh;
        background: linear-gradient(0deg, #6BCAD2 -150%, #21273D 80% );
        color: $white;
        padding-bottom: 5rem;
        transition: all 350ms ease-in-out;
    }
}
@media screen and (max-width: 1400px) {
    .dashboard {
        &__sidebar_container {
            display: none;
        }
    }
}

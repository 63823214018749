@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
    font-family: 'DM Sans', sans-serif;

    // dashboard links and username
    .links {
        font-weight: 400;
        font-size: 1rem;
    }

    .user_initials {
        font-weight: 700;
        line-height: 7.75rem;
        text-transform: uppercase;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 500;
    }

    h1 {
        font-size: 2.5rem; // 40px
    }

    h2 {
        font-size: 1.125rem; // 18px
    }
    h3 {
        font-size: 0.875rem; // 16px
    }
    h4 {
        font-size: 0.875rem; // 14px
    }

    h5 {
        font-size: 0.8125rem; // 13px
    }
    h6 {
        font-size: 0.75rem; // 12px
    }

    .module_main_title {
        font-size: 2.25rem;
        font-weight: 500;
        line-height: 2.25rem;
    }

    .module_main_sub_title {
        font-size: 1.3125rem;
        font-weight: 400;
        line-height: 2.25rem;
    }
    .module_resources {
        font-size: 1.375rem;
        font-weight: 500;
        line-height: 2.25rem;
    }
    .module_resources_filename {
        font-size: 1.25rem;
        line-height: 2.25rem;
        font-weight: 500;
    }
    .event_card__time {
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
    }
}
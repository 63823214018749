.header {
    position: relative;
    &__mobile_nav {
        height: 0;
        overflow: hidden;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        background-color: $white;
        color: $admiral-blue;
        transition: height 250ms ease-in-out;
        z-index: 100;
        li {
            list-style-type: none;
            margin-bottom: 1rem;
            padding: 1rem;
            text-align: center;
            cursor: pointer;
        }
    }
    .logout_wrapper {
        margin: 0 auto;
        display: flex;
        gap: 1rem;
        button {
            height: 40px;
            background-color: $soft-teal;
            color: $white;
            border-radius: 100px;
            width: 8rem;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }
    }
}

@media screen and (min-width: 720px) {
    .header__mobile_nav, .header__nav_toggle {
        display: none;
    }
}
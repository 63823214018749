.dashboard__certificates_unlocked {
    padding: 1.375rem 1.875rem 1.875rem;
    &__certificates_container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        justify-content: center;
        align-items: center;
        grid-row-gap: 20px;
        grid-column-gap: 10px;
        margin-top: 20px;
    }
}
@import './components/DesktopNav/DesktopNav';
@import './components/MobileNav/MobileNav';
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    padding: 0 1.5rem;
    background-color: $white;
    button {
        border: none;
        background: transparent;
    }
    .back_btn {
        width: 1.5rem;
        transition: all 250ms ease-in;
        font-size: 1rem;
        height: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 1rem;
        span {
            white-space: nowrap;
            overflow: hidden;
            animation: animate 250ms ease-in;
        }
        svg {
            color: $soft-teal
        }
        &:hover {
            width: 12.5rem;
        }
    }
}

@media screen and (min-width: 820px) {
    .header {
        padding: 0 3.4375rem 0 1.75rem;

    }
}
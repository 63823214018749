.skip-to-content__wrapper {
    position: absolute;
    top: -1000px;
    left: -1000px;
    width: 1px;
    height: 1px;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    margin: 0;
    
    &:focus-within {
        top: 0;
        left: 0;
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        margin: 8px 16px;
        gap: 8px;

        .skip-to-content__icon {
            display: flex;
            padding: 1rem;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.10);
        }

        .skip-to-content__text {
            display: block;
            padding: 14px;
            background-color: #515151;
            border: 1px solid #fff;
            border-radius: 5px;
            font-size: 22px;
            color: #fff;
            box-shadow: 0 0 5px 0 rgba(140,189,209,0.5);
            text-shadow: 1px 1px 2px #333;
        }
    }
}
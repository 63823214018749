@import './Tile/Tile';
@import './CardHeader/CardHeader';
.academy_card {
    width: 100%;
    padding: 0 1rem;
    background-color: $white;
    border-radius: 0.5rem;
    padding-bottom: 1.5rem;
    position: relative;
    overflow-y: hidden !important;
    box-shadow: 0 5px 10px -10px #21273D;
    transition: all 250ms ease-in-out;
    max-width: 500px;
    margin-top: 1rem;
    .tile_container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

@media screen and (min-width: 1080px) {
        .academy_card {
            max-width: 1020px;
            padding: 0 1.875rem;
            .tile_container {
                display: flex;
                flex-direction: row;
                margin-top: 1.875rem;
                gap: 1.5rem;
            }
        }
}

@media screen and (min-width: 1540px) {
    .academy_card {
        width: 1020px;
    }
}

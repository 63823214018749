@import './AcademyCard/AcademyCard';
@import './ComingMoreCard/ComingMoreCard';
@import './AcademiesList/AcademiesList';
@import './AcademiesList/DiscoverMoreCard/DiscoverMoreCard';

.dashboard__container {
    background-color: #f1f2f2;
}

.discover-more {
    max-width: 100%!important;
}

.dashboard__container__my_courses {
    color: $admiral-blue;
    width: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            width: 100%;
        }
        .academies-list-name {
            margin: 2rem 0;
            max-width: 500px;
        }
    }
}

.courses-tab {
    z-index: 1;
}


@media screen and (min-width: 1080px) {
    .dashboard__container__my_courses {
        &__container {
            h1 {
                margin: 2rem 0;
                width: 100%;
                max-width: 1020px;
            }
        }
    }
}

@media screen and (min-width: 1540px) {
    .dashboard__container__my_courses {
        padding: 5rem !important;
    }
}

.MuiTabs-indicator {
    background-color: $soft-teal!important
}

.MuiTab-root.Mui-selected {
    color: $soft-teal!important
}
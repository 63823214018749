.academy_card {
    header {
        margin: 1.5rem 0;
        padding-bottom: 0.5rem;
        position: relative;
        cursor: pointer;
        display: grid;
        height: 5rem;
        grid-template-columns: repeat(5, 1fr);
        align-items: center;
        gap: 0.5rem;
        .progress {
          margin-top: 0.25rem;
        }
        .done_circle {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-100%);
            background-color: $soft-teal;
            border-radius: 50%;
            height: 1.5rem;
            width: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
        }

        .next_lesson {
            border-bottom: 1px solid #D9D9D9;
            z-index: 1000;
        }
        .revist_course {
            z-index: 10;
        }
    }
    .clickable_header {
        height: 5rem;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
    }
}

@media screen and (min-width: 1080px) {
    .academy_card {
        header {
            position: relative;
            cursor: pointer;
            display: grid;
            height: 5rem;
            grid-template-columns: repeat(5, 1fr);
            align-items: center;
            transition: border-bottom 250ms ease-in-out;
            margin: 0;
            padding: 0;
            h4 {
                justify-self: center;
            }
            .progress, .certificate {
              justify-self: flex-end;
            }
            .done_circle {
                height: 30px;
                width: 30px;
                background-color: $soft-teal;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                justify-self: flex-end;
                grid-column: 5 / 5;
                top: 60%;
                transform: translateY(-80%)
            }
    
            .next_lesson {
                border-bottom: 1px solid #D9D9D9;
                justify-self: flex-end;
                grid-column: 5 / 5;
                z-index: 1000;
            }
            .revist_course {
                position: absolute;
                right: 100px;
            }
        }
    }
}

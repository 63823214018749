*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    width: 100%;
    background-color: #F1F2F2;
    color: #21273c;
    button {
        cursor: pointer;
    }
    
}

@import '../styles/common/ColorVariables';
@import '../styles/common/Mixins';
@import '../styles/common/Typography';
@import '../styles/common/default';

// components
@import '../components/Header/Header';
@import '../components/Footer/Footer';
@import '../components/Select/Select';
@import '../components/SkipToContent/SkipToContent';
@import '../components/UserProfile/UserProfile';



// main
@import '../routes/Login/Login';
// @import '../routes/LoginLoading/LoginLoading';
// @import '../routes/LoginAccessDenied/LoginAccessDenied';
@import '../routes/Dashboard/Dashboard';
@import '../routes/Academy/Academy';
@import '../routes/Calendar/CalendarPage';
@import '../routes/LiveSessions/LiveSessions';


//VIMEO VIDEO
.vimeo-player iframe {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
}
.user_profile {
    display: flex;
    align-items: center;
    margin: 0;

    &__initials {
        background-color: #6bcad2;
        color: $white;
        letter-spacing: -1px !important;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
    }
    &__name {
        width: 4.5625rem;
    }
}

@media screen and (max-width: 720px) {
    .user_profile {
        margin: 0 auto;
    }
    
}

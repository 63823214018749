.calendar_page {
    color: $admiral-blue;
    padding-bottom: 10rem;
    &__container {
        padding: 0 10rem 15rem;
       position: relative;
    }
    h1 {
        padding: 2rem 10rem;
    }
    .filter_academy_button {
        position: absolute;
        top: 0;
        right: 10rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 50px;
        width: 170px;
        border-radius: 0.5rem;
        border: 1px solid #21273c10;
        background-color: transparent;
        color: $admiral-blue;
        cursor: pointer;
        z-index: 10;
    }
    .filter_nav {
        position: absolute;
        right: 10rem;
        top: 40px;
        width: 170px;
        z-index: 10;
        background-color: $white;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: padding 150ms ease-in-out;
        background-color: #F1F2F2;
        color: $admiral-blue;
        padding: 0 0.5rem;
        border-radius: 0 0 0.5rem 0.5rem;
        li {
            list-style-type: none;
            padding: 0.5rem;
            transition: transform 150ms ease-in-out;
            cursor: pointer;
        }
        li:hover {
            color: $soft-teal;
        }
        .active-academy {
            border: 1px solid $soft-teal;
            border-radius: 6px;
            background-color: $white;
            color: $soft-teal;
        }
    }
    h1 {
        margin: 3.875rem 0 2.25rem 0;
    }
    .fc-daygrid-event-dot {
        display: none;
    }
    .fc-daygrid-event {
        background-color: #6bcad240;
        height: 100% !important;
    }
    .fc-scrollgrid-liquid {
        th {
            border: none;
        }
        border-radius: 8px;
        height: 865px !important;
    }

    .fc-col-header-cell {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        border: none;
        height: 2rem;
        padding: 1rem 0;
    }
    .fc {
        border: none;
    }
    .fc-daygrid-day-frame {
        .fc-daygrid-day-top {
            
            a {
                text-align: left !important;
                width: 100%;
            }
        }
    }
    .fc-view-harness {
        table {
            border: none;
            thead {
                border: none;
            }
            tbody {
                background-color: $white;
            }
        }
    }
    .fc-toolbar {
        display: flex;
        position: relative;
        height: 50px;
        h2 {
            font-size: 1rem;
        }
        
        .fc-toolbar-chunk:nth-child(1) {
            margin-left: 125px;
            height: 50px;
            width: 180px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
        }
        .fc-toolbar-chunk:nth-child(3) {
            position: absolute;
            width: 415px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .fc-button-group {
                button:nth-child(1) {
                    margin-right: 5px;
                }
            }
            button {
                border-radius: 8px;
                opacity: 1;
                text-transform: capitalize;
                background-color: transparent;
                color: $admiral-blue;
                height: 50px;
                width: 50px;
                border: 1px solid #21273c10;
            }
            .fc-today-button {
                color: $admiral-blue;
                font-weight: 500;
                width: 120px;
                border: 1px solid #21273c10 !important;
            }
        }
        .fc-toolbar-chunk:nth-child(2) {
            display: none;
        }
    }
    .fc-event-title  {
        font-weight: 500 !important;
        white-space: break-spaces;
        text-transform: capitalize;
        font-size: 14px;
    }
    .fc-day-today {
        background-color: rgba($soft-teal, 0.25)!important;
    }

    .fc-daygrid-event {
        padding: 1rem 1.5rem;
        display: flex !important;
        flex-direction: column-reverse;
        align-items: flex-start;
        position: relative;
        cursor: pointer;
    }
    .fc-daygrid-event:hover {
        background-color: #6bcad240;
    }
    .fc-daygrid-event::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 70%;
        background-color: $soft-teal;
        border-radius: 2px;
        top: 50%;
        left: 0.5rem;
        transform: translate(0%, -50%);
    }

    .fc .fc-daygrid-day-frame {
        padding: 0.5rem;
    }
}

@media screen and (max-width: 1500px) {
    .month_view_calendar {
        display: none;
    }
    h1 {
        padding: 0 1rem !important;
    }
    .calendar_page {
        &__container {
            padding: 0 1rem;
            max-width: 720px !important;
        }
        
        .fc-view-harness  {
            height: 50vh !important;
            margin-top: 80px;
        }
        .fc-toolbar-chunk {
            width: auto;
        }
        .fc-toolbar-chunk:nth-child(1) {
            margin-left: 0 !important;
        }
        .fc-toolbar-chunk:nth-child(3) {
            margin-top: 150px;
            width: auto !important;
        }
    }
}

@media screen and (max-width: 720px) {
    .calendar_page {
        
        .fc-view-harness  {
        }
        .fc-list-table {
            margin-top: 40px;
            max-width: 720px !important;
            .fc-list-day-cushion {
                background-color: white;
                margin: 1rem 0;
            }
        }
        .fc-toolbar-chunk {
            min-width: 100% !important;
        }
        .fc-toolbar-chunk:nth-child(1) {
            position: absolute;
            top: 150px;
            border: none !important;
        }

        &__container {
            .filter_academy_button {
                right: 16px;
            }
            .filter_nav {
                right: 16px;
            }
        }
    }
}

@media screen and (min-width: 1500px) {
    .list_view_calendar {
        display: none;
    }
}

.tile_container__tile {
    position: relative;
    background: linear-gradient(0deg, $green-1 20%, $green-2 100%);
    color: $white;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    box-shadow: 0 5px 10px -5px #21273D;
    min-height: 170px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
    * {
        z-index: 2;
    }
    .ribbon-icon-wrapper {
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        padding: 1rem;
        border-radius: 50%;
        background-color: $soft-teal;
        display: flex;
        align-items: center;
        justify-content: center;
        right: -1rem;
        top: -1rem;
    }
    .tile_icon {
        color: $soft-teal;
        font-size: 1rem;
        margin-right: 0.5rem;
    }
    .info_wrapper {
        width: 100%;
        display: flex;
        .text_wrapper {
            margin-top: 13.5px;
            h4 {
                text-transform: uppercase;
                opacity: 0.5;
            }
        }
        .completed-text {
            font-size: 0.875rem;
        }
        .button_wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        .again-icon-wrapper {
            background-color: $soft-teal;
        }
        button {
            border: none;
            width: 1.375rem;
            height: 1.375rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            .play-icon {
                color: $soft-teal;
                font-size: 1rem;
            }
            .again-icon {
                color: white;
                font-size: 1.2rem;
            }
        }
    }
}

@media screen and (min-width: 1080px) {
    .tile_container__tile {
        max-width: 300px;
    }
}

.tile_header_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    .tile_header {
        text-transform: uppercase;
        opacity: 0.5;
    }
}

.tile-top {
    .tile_title {
        font-size: 1.3rem;
        margin-top: 0.5rem;
    }
}


@import './Sidebar/Sidebar';
@import './ModuleInformation/ModuleInformation';
@import './ModuleHeader/ModuleHeader';


@media screen and (min-width: 800px) {
    .academy {
        &__container {
            min-height: 100vh;
            grid-template-columns: auto 1fr;
            display: grid;
        }
        &__grid_wrapper {
            width: calc(100vw - 26rem);
            .button_wrapper {
                max-width: 1020px;
                margin: 2.5625rem auto 10rem;
                display: flex;
                justify-content: space-between;
                button {
                    width: 11.875rem;
                    height: 3.375rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid $soft-teal;
                    background-color: transparent;
                    border-radius: 7rem;
                }
            }
        }
    }
}
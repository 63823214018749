.dashboard__badges {
  padding-top: 1.5rem;
  position: relative;
  border-top: 1px solid rgba($white, 0.1);

  &__badges_container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.5rem;
  }

}

.discover_more_container {
  position: relative;
  background: linear-gradient(0deg, $green-1 20%, $green-2 100%);
  color: $white;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  box-shadow: 0 5px 10px -5px #21273D;
  min-height: 170px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  width: 19rem;
  height: 100%;
  margin-right: 1.2rem;
  font-size: 0.875rem;
  .discover_more_content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .icon-wrapper {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    padding: 1rem;
    border-radius: 50%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -1rem;
    top: -1rem;
    font-size: rem;
  }
  .image {
    width: 100%;
    height: 10.5rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .image_overlay {
      background: linear-gradient(to top right, rgba(32, 39, 61, 0.9), rgba(0, 0, 0, 0));
      border-radius: 4px;
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }
  * {
    z-index: 2;
  }
  .content {
    padding: 1rem;
    .title {
      font-size: 1.5rem;
      margin-top: 0.5rem;
    }
    .prices_wrapper {
      margin: 0.5rem 0;
      .price {
        font-size: 1rem;
        color: $soft-teal;
        margin-right: 0.5rem;
      }
      .price.discounted {
        font-size: 1rem;
        text-decoration: line-through;
      }
    }
    .description {
      max-height: 5.3rem;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0.5rem 0;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
    }
  }
  .actions-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    .goto-icon-wrapper {
      border: none;
      width: 1.375rem;
      height: 1.375rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: $soft-teal;
    }
  }
}
.lesson-container {
    padding: 1rem;
    .completion-container {
        background-color: $white;
        max-width: 1020px;
        color: $admiral-blue;
        border-radius: 0.5rem;
        box-shadow: 0px 1px 1px rgba(33, 39, 61, 0.1);
        width: 100%;
        margin: 2rem auto;
        padding: 2rem;

        .card {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 0.5rem;
            width: 100%;
            position: relative;
            color: inherit;
            text-decoration: none;

            button {
                display: flex;
                font-size: 1rem;
                align-items: center;
                justify-content: center;
                background: linear-gradient(90deg, $royal-blue 0%, $soft-teal 100%);
                border:0;
                border-radius: 4px;
                color: white;
                border-radius: 2rem;
                margin-top: 1rem;
                padding: 1rem 2rem;
            }

            .done_circle{
                height: 24px;
                width: 24px;
                padding: 4px;
                background-color: #6bcad2;
                border-radius: 50%;
                color: #fff;
                margin-right: 8px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }
        }


        .button_wrapper {
            background-color: red;
            display: flex;
            justify-content: space-between;
        }
    }

    .next-up-container {
        flex-direction: column;
        display: flex;
        gap: 10px;

        img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: 5px;
        }

        .next-up-thumbnail-container:hover .playIcon {
            transform: scale(1.1)
        }

        .next-up-thumbnail-container {
            position: relative;
            width: 295px; 
            height: 165px;
            cursor: pointer;


            svg {
                transition: transform 0.3s ease-in-out;
                border: 2px solid white;
                border-radius: 100px;
                padding: 10px;
                z-index: 10;
                position: absolute;
                color: white;
                left: 0; 
                right: 0; 
                top: calc(50% - 25px);
                margin-left: auto; 
                margin-right: auto; 
                width:50px;
                height: 50px
            }

            .playIcon {
                padding: 2px;
            }
    
        }

        .please-finish-video-error-message {
            opacity: 0%;
            overflow: hidden;
            text-wrap: nowrap;
            transition: opacity 0.3s ease-in-out;
        }
    }

    .lesson__grid_wrapper__module_information {
        background-color: $white;
        max-width: 1020px;
        margin: 0 auto;
        color: $admiral-blue;
        border-radius: 0.5rem;
        box-shadow: 0px 1px 1px rgba(33, 39, 61, 0.1);
        width: 100%;
        padding: 2rem;
        .title_wrapper {
            .module_main_webinar_status{
                margin-bottom: 1.625rem;
                opacity: 0.5;
                display: flex;
                justify-content: space-between;
                gap: 0.5rem;
                .next-session{
                    flex: 0 1 auto;
                }
                .live-status{
                    flex: 0 0 auto;
                    text-align: right;
                    text-transform: uppercase;
                    display: inline-flex;
                    align-items: center;
                    gap: 0.45rem;
                    letter-spacing: 1px;
                    svg{
                        circle{
                            fill: $light-stone-gray;
                        }
                        
                        &.blinking{
                            circle{
                                fill: red;
                            }
                        }
                    }
                }
                @media screen and (max-width: 450px) {
                    flex-wrap: wrap;
                    .live-status{
                        order: -1;
                    }
                }
            }
            margin-bottom: 1.625rem;
            .module_main_sub_title {
                opacity: 0.5;
            }
        }
        p {
            margin-bottom: 1.625rem;
        }
        .resources {
            margin-top: 4rem;
            p {
                opacity: 0.5;
            }
            &__card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 2rem 1rem;
                background-color: #F1F2F2;
                border-radius: 0.5rem;
                margin-top: 1rem;
                position: relative;
                color: inherit;
                text-decoration: none;
                .file {
                    background-color: $soft-teal;
                    padding: 1.25rem 1.5rem;
                    border-radius: 0.5rem;
                    align-items: flex-end;
                    margin-bottom: 1rem;
                }
                button {
                    background-color: $white;
                    width: 3.375rem;
                    height: 3.375rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    border-radius: 50%;
                    position: absolute;
                    top: 2rem;
                    right: 1.25rem;

                }
            }
        }

        .button_wrapper {
            background-color: red;
            display: flex;
            justify-content: space-between;
        }
    }

    @media screen and (min-width:1200px) {
        padding: 2rem;
        .completion-container {
            padding: 3.125rem 3.8125rem;
        }
        .lesson__grid_wrapper__module_information {
            
            padding: 3.125rem 3.8125rem;

            .resources {
                &__card {
                    display: grid;
                    padding: 1.25rem 2rem 1.25rem 1.25rem;
                    grid-template-columns: auto auto auto;
                    justify-items: center;
                    align-items: center;
                    .text_wrapper {
                        margin-bottom: 0;
                    }

                    p {
                        margin-bottom: 0;
                    }
                    button {
                        position: relative !important;
                        top: 0;
                        right: 0;
                    }
                    .file {
                        margin-bottom: 0;
                    }
                    .text_wrapper {
                        margin: 0 1rem;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1400px) {
        .lesson__grid_wrapper__module_information {
            .resources {
                &__card {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    .text_wrapper {
                        flex: 1;
                    }
                }
            }
        }
    }
}

.blinking {
    @include keyframes(blink){
        0%{
          opacity: 0;
        }
        50%{
          opacity: 1;
        }
        100%{
          opacity: 0;
        }
    }
    @include animate(blink, 1s, ease, infinite);
}

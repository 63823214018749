.dashboard__coming_more{
    width: 100%;
    margin-top: 2rem;
    padding: 1rem 1rem 1.5rem 1rem;
    background: radial-gradient(86.11% 86.12% at 0% 100%, $royal-blue 0%, $soft-teal 100%);
    border-radius: 8px;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 1020px;

    &_title{
        font-size: 1.6rem;
        color: white;
    }

    p {
      font-size: 1rem;
      color: white;
    }
}

@media screen and (min-width: 1080px) {
  .dashboard__coming_more {
    padding: 1.5rem 1.875rem;
  }
}

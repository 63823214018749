.footer {
  height: 4rem;
  padding: 0 1.5rem;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  .year {
    margin-left: 1rem;
  }
}
.lesson__sidebar__header {
  padding: 3rem 2rem;
  position: relative;

  .progress_bar-container {
    height: 4px;
    width: 100%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 5px;

    &::before {
      content: '';
      background-color: $white;
      opacity: 0.1;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .progress_bar {
    height: 100%;
    display: flex;
    background-color: $soft-teal;
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
  }

  .text_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    border: none;
    color: $white;
  }

  hr {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 1px;
    opacity: 0.1;
  }
}

.academies-list-container {
  width: 90vw;
  position: relative;
  .next {
    right: 0;
  }
  .prev {
    left: 0;
    
  }
  .prev,.next {
    z-index: 12;
    color: $soft-teal;
    font-size: 40px;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 20px);
  }
}

.academies-list-container.show-next {
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 150px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(241, 242, 242, 1) 100%);
    z-index: 10;
  }
}

.academies-list-container.show-prev {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 150px;
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(241, 242, 242, 1) 100%);
    z-index: 10;
}
}


.academies-list {
  display: flex;
  overflow-x: hidden;
  padding: 20px 0;
  scroll-behavior: smooth;
  scrollbar-width: none;
  .tile_container {
      margin-right: 1.2rem;
      position: relative;
      width: 19rem;
      .tile_container_overlay {
        background: linear-gradient(to top right, rgba(32, 39, 61, 0.9), rgba(0, 0, 0, 0));
        border-radius: 4px;
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
      }
  }
}
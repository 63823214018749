.dashboard__sidebar__header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2.625rem 0;
    text-align: center;
    position: relative;
    .edit_button {
        width: 9.5rem;
        height: 3.375rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: 1px solid $soft-teal;
        color: $white;
        border-radius: 7rem;
        margin-top: 1.0625rem;
        transition: all 250ms ease-in-out;
        img {
            margin-right: 0.453125rem;
        }
    }
    .edit_button:hover {
        background-color: $soft-teal;
    }
    hr {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $white;
        opacity: 0.1;
    }
}